
import React, { useState, useEffect } from "react";
import "./Output.css";
import "./TextReveal.css";



const TextReveal = ({ text, delay }) => {
    const [words, setWords] = useState([]);
    const [animationKey, setAnimationKey] = useState(0);

    useEffect(() => {
        const splitWords = text.split(" ").map((word, index) => (
            <span
                key={index}
                className="word"
                style={{
                    animation: `fadeIn 0.3s ${delay * index}ms forwards`,
                }}
            >
                {word}&nbsp;
            </span>
        ));
        setWords(splitWords);
        setAnimationKey((prevKey) => prevKey + 1);
    }, [text, delay]);

    return <div key={animationKey} className="output" >{words}</div>;
};


const Output = ({ output }) => {

    return (
        <div className="output">{output && <TextReveal text={output} delay={50} />}</div>
    );
}


export default Output;