import React, { useState, useEffect } from 'react';
import './App.css';
import MainWindow from './components/MainWindow/MainWindow';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

const API_BASE_URL = "https://gptrolley.francecentral.cloudapp.azure.com/api";

interface GenerateResult {
    decision: string;
    explanation: string;
    choice: any; // Replace 'any' with the appropriate type if known
}

interface GenerateResponse {
    result: GenerateResult;
}

function App() {

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [input1, setInput1] = useState<string>('');
    const [input2, setInput2] = useState<string>('');
    const [output, setOutput] = useState<string>('');

    // Parse URL parameters on component mount
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const left = params.get('left') || '';
        const right = params.get('right') || '';
        if (left) setInput1(left);
        if (right) setInput2(right);

        // If both inputs are present, generate the output
        if (left && right) {
            // please_generate_output(left, right);
        }
    }, [location.search]);


    const generateOutput = async (i1: string, i2: string): Promise<void> => {
        console.log('Sending inputs to backend:', i1, i2);
        setOutput("Generating output...");
        try {
            const response = await fetch(`${API_BASE_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ input1: i1, input2: i2 }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: GenerateResponse = await response.json();
            console.log('Received output from backend:', data);
            const result = data.result;
            const decision = result.decision;
            const explanation = result.explanation;
            const choice = result.choice;
            const sep = decision[decision.length - 1] === '.' ? ' ' : '. ';
            const outputText = decision + sep + explanation;

            setOutput(outputText);
        } catch (error: any) {
            console.error('Error during fetch:', error);
            setOutput('Error generating output');
        } finally {
            setIsLoading(false);
        }
    };

    const please_generate_output = (): void => {
        setIsLoading(true);
        const input1Element = document.getElementById('input1') as HTMLInputElement | null;
        const input2Element = document.getElementById('input2') as HTMLInputElement | null;



        let input1Value = input1 || (input1Element?.placeholder || '');
        let input2Value = input2 || (input2Element?.placeholder || '');

        // Construct new URL parameters
        const newParams = new URLSearchParams();
        newParams.set('left', input1Value);
        newParams.set('right', input2Value);


        // Get current URL parameters
        const currentParams = new URLSearchParams(location.search);

        // Only navigate if the parameters have changed to avoid infinite loops
        if (currentParams.toString() !== newParams.toString()) {
            // Update the URL without adding a new entry to the history stack
            navigate(`/?${newParams.toString()}`, { replace: true });
        }
        generateOutput(input1Value, input2Value);
    };

    return (
        <div className="App">
            <MainWindow
                is_loading={isLoading}
                input1={input1}
                input2={input2}
                setInput1={setInput1}
                setInput2={setInput2}
                output={output}
                button_click={please_generate_output}
            />
        </div>
    );
}

export default App;
