import React, { useEffect } from 'react';
import './Ad.css';

interface AdProps {
    client: string;
    slot: string;
    dataAdTest: string;
}

const Ad: React.FC<AdProps> = ({ client, slot, dataAdTest }) => {
    useEffect(() => {
        try {
            // @ts-ignore
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (ex) {
            console.error("Adsense error", ex);
        }
    }, []);

    return (
        <ins className="adsbygoogle"
            // style={{ border: "1px solid blue" }}
            data-ad-client={client}
            data-ad-slot={slot}
            data-ad-test={dataAdTest}></ins>
    );
};

export default Ad;
