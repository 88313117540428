import './ButtonGenerate.css';


const ButtonGenerate = ({ is_loading, button_click }) => {
    return (
        <button disabled={is_loading} className="buttonGenerate" onClick={button_click}>
            Ask ChatGPT
        </button>

    );
}


export default ButtonGenerate;