import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Ad from './components/Ad/Ad';
import './index.css';
import Help from './components/Help/Help';
import Footer from './components/Footer/Footer';
import { BrowserRouter } from 'react-router-dom';

// Get the root container to mount your app
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// Render your App component to the DOM
root.render(
    <BrowserRouter>
        <>
            <Help />
            <App />
            <Ad client="ca-pub-2620096876082429" slot="5116718707" dataAdTest="on" />
            <Footer />
        </>
    </BrowserRouter>
);
