import './Form.css';
import { useState, useEffect } from 'react';
import { examples } from './examples';


const Form = ({ input1, setInput1, input2, setInput2 }) => {

    const [placeholders, setPlaceholders] = useState(['', '']);
    useEffect(() => {
        setPlaceholders(examples[Math.floor(Math.random() * examples.length)])
    }, []);


    return (
        <div className="inputContainer">
            <input
                id="input1"
                type="text"
                className="input"
                placeholder={placeholders[0]}
                value={input1}
                onChange={(e) => setInput1(e.target.value)}
            />
            <input
                id="input2"
                type="text"
                className="input"
                placeholder={placeholders[1]}
                value={input2}
                onChange={(e) => setInput2(e.target.value)}
            />
        </div>
    );
}


export default Form;