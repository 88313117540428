// src/components/Footer/index.js

import React from 'react';
import './Footer.css';
import { github_href, twitter_href, patreon_href, kofi_href } from '../../constants';
import kofiIcon from './kofi-icon.png';

const Footer = () => {

  return (
    <div className="footer">
      <div className="footer-left">
        <p>Made by Antoine Stark</p>
      </div>
      <div style={{ margin: "2px" }} />
      <div className="footer-right">
        {'' && <a href={github_href} target="_blank" rel="noopener noreferrer">
          <img src={require("./github-icon.svg").default} alt="GitHub icon" />
        </a>}
        <a href={twitter_href} target="_blank" rel="noopener noreferrer">
          <img src={require("./twitter-icon.svg").default} alt="Twitter icon" />
        </a>
        <a href={patreon_href} target="_blank" rel="noopener noreferrer">
          <img src={require("./patreon-icon.svg").default} alt="Patreon icon" />
        </a>
        <a href={kofi_href} target="_blank" rel="noopener noreferrer">
          <img src={kofiIcon} alt="Kofi icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;