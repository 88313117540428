import { useEffect } from 'react';

import './MainWindow.css';
import Form from '../Form/Form';
import ButtonGenerate from '../ButtonGenerate/ButtonGenerate';
import Output from '../Output/Output';


const updateFontSize = (container) => {
    const minRatio = 0.7;
    const maxRatio = 0.9;
    const step = 0.9;
    const ratio = container.scrollHeight / document.documentElement.clientHeight;

    const fsc = document.documentElement.style.getPropertyValue("--fsc") - 0;
    if (!fsc) {
        document.documentElement.style.setProperty("--fsc", 1);
        return;
    }
    console.log("resizing prompt: fsc=", fsc, ", ratio=", ratio);

    var newfsc = fsc;

    if (ratio > maxRatio) {
        newfsc = fsc * step;
    }
    if (ratio < minRatio) {
        newfsc = fsc / step;
    }
    if (newfsc > 1) newfsc = 1;
    document.documentElement.style.setProperty("--fsc", newfsc);
};




const MainWindow = ({ is_loading, input1, setInput1, input2, setInput2, output, button_click }) => {

    //resize
    useEffect(() => {
        const container = document.querySelector(".mainWindow");
        if (!container) return;

        updateFontSize(container);
        const resizeObserver = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                updateFontSize(container);
            });
        });


        resizeObserver.observe(container);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    return (
        <div className={`mainWindow ${is_loading ? 'loading' : ''}`} >
            <div className="logo">GPTrolley.com</div>
            <h1>There is a runaway trolley barreling down the railway tracks...</h1>
            <Form input1={input1} setInput1={setInput1} input2={input2} setInput2={setInput2} />
            <ButtonGenerate is_loading={is_loading} button_click={button_click} />
            <Output output={output} />
        </div >
    );
}


export default MainWindow;